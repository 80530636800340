.icon--rounded {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  img {
    width: 25px;
  }

  @include media-breakpoint-down(md) {
    width: 32px;
    height: 32px;

    img {
      width: 15px;
    }

  }
}

.avatar--xs {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.avatar--xxs {
  width: 40px;
  height: 40px;
}

.icon--img {
  width: 16px;
  margin-right: 10px;
}

.icon--table {
  width: 24px;
  margin-right: 5px;
  margin-top: -10px;
}

.icon-svg {
  &.icon-download {
    fill: #EA6D1F;
  }
}

.marker-info{
  position: absolute;
  left: -90px;
  top: -65px;
  font-size: 14px;text-align: center;
  width: 200px;height: 60px ;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px #9698A740;
  padding: 3px;

}
.marker-info::after{
  content: '';
  position: absolute;
  top: 98%;
  left: calc(50% - 10px);
  background: #FFFFFF;
  width: 20px;
  height: 5px;

  /* The points are: (left top: x y, right top: x y, center bottom: x y) */
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.marker-info-header{
  background-color: $gray1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
