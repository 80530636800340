.dropzone {
  .dz {
    &__message {
      align-items: center;
      display: flex;
    }

    &__avatar {
      width: 70px;
      height: 70px;
      background-color: $gray14;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 50%;
    }

    &__preview {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
}
