$font-family-base: dmsans;
$headings-font-family: dmsansbold;
$headings-font-weight: normal;
$headings-margin-bottom: 0;

$font-size-base: 1rem;
$h1-font-size: 1.875rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.125rem;
$line-height-base: 1.4;

$gray1: #F0F0F0;
$gray2: #BCBDC1;
$gray3: #E5E5E5;
$gray4: #D7DBDE;
$gray5: #F7F8FA;
$gray6: #C9CACE;
$gray7: #999998;
$gray8: #898989;
$gray10: #D8D8D8;
$gray11: #DFE0EB;
$gray12: #ABABAB;
$gray13: #585353;
$gray14: #E3E3E3;
$gray15: #DEDEDE;
$blue1: #013854;
$black1: #3C3C3B;
$black2: #686868;
$green1: #166B8E;
$green2: #6D9C90;
$green4: #93AFB0;
$orange1: #E6D6B2;
$orange2: #EC7B1A;
$orange3: #EA6D1F;
$orange4: #FCD5B0;
$yellow1: #D6AE3C;
$red1: #FE495F;
$red2: #FFEDEF;

// new colors
$blue2: #1C2E69;
$orange5: #E8E0CA;
$black3: #0A0A0A;

$body-color: $black1;
$body-bg: white;
$primary: #1D2D68;
$secondary: #9B7F57;
$dark: $blue2;

$danger: $red1;
$warning: $orange3;


$btn-font-family: dmsansbold;
$btn-font-size: 1.125rem;
$btn-border-radius: 10px;
$btn-padding-y: 0.9rem;
$btn-padding-x: 2rem;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-box-shadow: none;
$link-decoration: none;

$btn-padding-y-sm: 0.75rem;
$btn-padding-x-sm: 1rem;
$btn-border-radius-sm: 10px;

$input-placeholder-color: $gray7;
$input-border-radius: 10px;
$input-border-color: $gray2;
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;
$input-focus-box-shadow: none;
$input-focus-border-color: $body-color;
$input-border-radius-sm: $input-border-radius;
$input-font-size-sm: 1rem;
$input-disabled-bg: '#ffffff';
$input-disabled-border-color:'#DFE0EB';

$form-floating-padding-y: 0.5rem;
$form-floating-height: 3.125rem;
$form-floating-label-transform: scale(.9) translateY(-.5rem) translateX(.15rem) !default;

$navbar-light-color: white;
$navbar-light-hover-color: white;
$navbar-light-active-color: white;
$navbar-light-toggler-border-color: transparent;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

$modal-header-border-width: 0;
$modal-header-border-color: red;
$modal-md: 560px;
$modal-content-border-radius: 20px;
$modal-content-border-color: none;
$modal-backdrop-bg: #000000;

$accordion-border-width: 0;
$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 0;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $body-color;
$accordion-icon-color: $warning;
$accordion-icon-active-color: $warning;
$accordion-icon-width: 1rem;
$accordion-body-padding-y: 0.5rem;
$accordion-body-padding-x: 0.5rem;

$pagination-border-width: 0;
$pagination-bg: transparent;
$pagination-color: $body-color;
$pagination-border-radius: 10px;
$pagination-focus-box-shadow: none;
$pagination-focus-color: $body-color;
$pagination-focus-bg: inherit;
$pagination-hover-color: $body-color;
$pagination-hover-bg: inherit;

$badge-padding-y: .50em;
$badge-border-radius: 35px;

$transition-collapse: height .0s ease !default;

$form-switch-checked-bg-image: url('data:image/svg+xml,<svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8L3.1875 13L1 10.7273" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <g filter="url(#filter0_d)"><circle cx="24.6" cy="10.0001" r="9.6" fill="white"/></g><defs><filter id="filter0_d" x="11" y="0.400146" width="27.2" height="27.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>') !default;
$form-switch-bg-image: url('data:image/svg+xml,<svg width="45" height="20" viewBox="0 0 45 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8182 7.72723C29.7412 7.72723 29 8.65434 29 9.62806V13.0991C29 14.0729 29.7412 15 30.8182 15H37.1818C38.2588 15 39 14.0729 39 13.0991V9.62806C39 8.65434 38.2588 7.72723 37.1818 7.72723H30.8182ZM33.9998 10C33.7128 10 33.4801 10.2326 33.4801 10.5196V11.0614C33.1696 11.2232 32.9606 11.5254 32.9606 11.8716C32.9606 12.3881 33.4259 12.8069 33.9999 12.8069C34.5739 12.8069 35.0391 12.3881 35.0391 11.8716C35.0391 11.5253 34.8301 11.223 34.5194 11.0613V10.5196C34.5194 10.2326 34.2868 10 33.9998 10Z" fill="white"/> <path d="M31.7273 8.63636V7.0202C31.7273 6.48441 31.9667 5.97056 32.3929 5.5917C32.8191 5.21284 33.3972 5 34 5C34.6028 5 35.1808 5.21284 35.6071 5.5917C36.0333 5.97056 36.2727 6.48441 36.2727 7.0202V8.63636" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <g filter="url(#filter0_d)"> <circle cx="13.6" cy="10.0001" r="9.6" fill="white"/> </g> <defs> <filter id="filter0_d" x="0" y="0.400146" width="27.2" height="27.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="4"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/> </filter> </defs> </svg> ') !default;
$form-switch-focus-bg-image: url('data:image/svg+xml,<svg width="45" height="20" viewBox="0 0 45 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8182 7.72723C29.7412 7.72723 29 8.65434 29 9.62806V13.0991C29 14.0729 29.7412 15 30.8182 15H37.1818C38.2588 15 39 14.0729 39 13.0991V9.62806C39 8.65434 38.2588 7.72723 37.1818 7.72723H30.8182ZM33.9998 10C33.7128 10 33.4801 10.2326 33.4801 10.5196V11.0614C33.1696 11.2232 32.9606 11.5254 32.9606 11.8716C32.9606 12.3881 33.4259 12.8069 33.9999 12.8069C34.5739 12.8069 35.0391 12.3881 35.0391 11.8716C35.0391 11.5253 34.8301 11.223 34.5194 11.0613V10.5196C34.5194 10.2326 34.2868 10 33.9998 10Z" fill="white"/> <path d="M31.7273 8.63636V7.0202C31.7273 6.48441 31.9667 5.97056 32.3929 5.5917C32.8191 5.21284 33.3972 5 34 5C34.6028 5 35.1808 5.21284 35.6071 5.5917C36.0333 5.97056 36.2727 6.48441 36.2727 7.0202V8.63636" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <g filter="url(#filter0_d)"> <circle cx="13.6" cy="10.0001" r="9.6" fill="white"/> </g> <defs> <filter id="filter0_d" x="0" y="0.400146" width="27.2" height="27.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/> <feOffset dy="4"/> <feGaussianBlur stdDeviation="2"/> <feComposite in2="hardAlpha" operator="out"/> <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/> <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/> </filter> </defs> </svg> ') !default;


$progress-height:                   0.37rem !default;
//$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       $gray14 !default;
//$progress-border-radius:            $border-radius !default;
//$progress-box-shadow:               $box-shadow-inset !default;
//$progress-bar-color:                $white !default;
//$progress-bar-bg:                   $primary !default;
//$progress-bar-animation-timing:     1s linear infinite !default;
//$progress-bar-transition:           width .6s ease !default;

$dropdown-divider-bg: white