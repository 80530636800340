.table--info {
  margin-bottom: 0;

  &.table-borderless {
    tbody tr {
      border-bottom: 0;

      td {
        color: $body-color;
      }

      &:first-child td {
        padding-top: 1rem;
      }
    }
  }

  &:not(.table-borderless) {
    tbody tr td {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }
  }

  tr {
    border-bottom: 1px solid $gray4;
  }

  &.table > :not(:last-child) > :last-child > * {
    border-bottom-color: $gray4;
  }

  th {
    color: $gray8;
    text-transform: uppercase;
    font-family: dmsansbold, Arial, sans-serif;
    font-size: $font-size-base * .75;
  }

  th, td {
    padding-left: 0;
    padding-right: 0;
  }
}

.table--admin {
  margin: 0;

  thead th {
    background-color: $gray1;
    border: 0;
    font-family: dmsansbold, Arial, sans-serif;
    text-transform: uppercase;
    color: $black3;
    font-size: $font-size-sm;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 51px;
      border-bottom-left-radius: 51px;
      padding-left: 1rem;
    }

    &:last-child {
      border-top-right-radius: 51px;
      border-bottom-right-radius: 51px;
    }
  }

  tbody {
    td {
      border: 0;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      color: $body-color;
    }

    tr:first-child td {
      padding-top: 1rem;
    }
  }
}
