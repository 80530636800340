.border-top--gray4 {
  border-top-color: $gray4 !important;
}

.border-top--gray11 {
  border-top-color: $gray11 !important;
}

.app-loading {
  position: fixed !important;
  z-index: 100000;
  left: 50%;
  top: 50%;

  &:before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.app-component-loading {
  &:before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 20000;
  }

  &:after {
    content: ' ';
    display: inline-block;
    width: $spinner-width;
    height: $spinner-height;
    vertical-align: text-bottom;
    border: $spinner-border-width solid $primary;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-no-pointer {
  cursor: default !important;
}

.button-play {
  position: absolute;
  z-index: 1000;
  top: 29%;
  left: 40%;
  color: white !important;
  font-size: 25px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0;

  i {
    z-index: 1000;
  }
}


.legend-chart {
  width: 16px;
  height: 4px;
  border-radius: 2px;

  &.bg-orange {
    background-color: $orange5 !important;
  }

  &.bg-black {
    background-color: $black1 !important;
  }

  &.bg-gray {
    background-color: $gray14 !important;
    height: 8px;
  }
}

.h-md-100 {
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

.app-no-info {
  &:before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    z-index: 1000;

    @include media-breakpoint-up(lg) {
      height: 103%;
      width: 103%;
      left: -20px;
      top: -20px;
    }
  }
}

.app-no-info--text {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 20%;
  z-index: 1000;
}

.app-avatar1 {
  width: 40px;
  height: 40px;
  background-color: $gray14;
  border-radius: 50%;
}

.bg--grey {
  background: #EBEDF0 !important;
}

.no-wrap {
  white-space: nowrap
}
