.historic__card-mobile{
  .card-header {
    border-bottom: 1px solid $gray4;
    padding: 0;
    padding-bottom: 0.5rem;
    margin: 1rem;
    margin-bottom: 0;
  }
}

.card--primary {
  background: $primary;
  box-shadow: 0 5px 10px rgba(150, 152, 167, 0.15);
  border-radius: 25px;

  @include media-breakpoint-up(md) {
    &__container {
      height: 100%;
    }
  }
  @include media-breakpoint-down(md) {
    border-radius: 20px;
  }
}

.card--light {
  background: #FFFFFF;
  border-radius: 25px;
  border: 0;

  .card-header, .card-body .text-title {
    color: $secondary;
  }

  &.card--sm {
    .card-body {
      padding: 1rem !important;
    }
  }

  @include media-breakpoint-down(md) {
    border-radius: 20px;
  }
}
.card--base-light {
  background: #EBEDF0 !important;
}

.tile--light {
  .card-body {
    align-items: center;
    display: flex;
  }

  @include media-breakpoint-down(md) {
    .card-body {
      align-items: flex-start;
    }
  }
}

.info-card--light {
  background-color: $gray5;
  border-radius: 10px;
  padding: 1rem;
}

.card--sm {
  padding: 0;

  .card-body {
    padding: 1.1rem;

    @include media-breakpoint-only(md) {
      padding: 0.8rem 2rem;
    }
    @include media-breakpoint-down(md) {
      padding: 0.8rem;
    }
  }
}

.card-img {
  border-radius: 25px;

  @include media-breakpoint-down(md) {
    border-radius: 20px;
  }
}
