div[class*='nav__item'] {
  .dropdown-toggle {
    font-size: 0.875rem;
    font-family: dmsansmedium, Arial, sans-serif;

  }

  .dropdown-item {
    font-size: 0.875rem;
    font-family: dmsansmedium, Arial, sans-serif;
    cursor: pointer;
    padding: 0.5rem;

    &:hover, &:active {
      background-color: transparent;
    }
  }
}

.navbar-toggler {
  box-shadow: none !important;
}

.navbar-brand {
  height: 56px;
  align-items: center;
  display: flex;
}

.nav {

  &__item-investment {
    max-width: 200px;
    min-width: 200px;

    .dropdown {
      width: 100%;
    }

    .dropdown-toggle {
      border: 1px solid white;
      border-radius: 7px;

      &::after {
        position: absolute;
        right: 10px;
        top: 16px
      }

      &[aria-expanded="true"] {
        border-radius: 7px 7px 0 0;
        border-bottom: 0;
      }
    }

    .dropdown-menu {
      background-color: $primary;
      border: 1px solid white;
      border-radius: 0 0 7px 7px;
      border-top: 0;
      max-width: 200px;
      min-width: 200px;
      margin-top: 0;
      padding-top: 0;
      overflow: auto;
      max-height: 300px;
      z-index: 899999999999999;
      .dropdown-item {
        color: white;
      }
      .dropdown-divider {
        color: white;
      }
    }
  }

  &__item-user {
    margin-left: 2rem;

    .dropdown-toggle {
      &::after {
        margin-left: 0.625rem;
      }
    }

    .dropdown-menu {
      box-shadow: 0 10px 10px rgba(150, 152, 167, 0.15);
      border-radius: 10px;
      border: 0;
      padding: 0.625rem;
      z-index: 899999999999999;

      .dropdown-divider {
        border-top: 0.5px solid $gray4;
      }

      .dropdown-item:first-child:active, > .dropdown-item:first-child:hover {
        background-color: $primary !important;
        color: white
      }
    }
  }

  &__item--btn-admin {
    padding: 0.5rem 1.5rem;
    height: 35px;
    font-size: 0.875rem;
    border-radius: 7px;
    margin-right: 1rem;
  }

  &__item-admin {
    .dropdown-toggle.nav-link {
      font-family: dmsansbold, Arial, sans-serif;
    }
  }
}

.nav__subheader {
  background: white;
  box-shadow: 0 5px 10px rgba(150, 152, 167, 0.1);

  .nav-tabs {
    border: 0;

    .nav-link {
      border: 0;
      color: $primary !important;
      cursor: pointer;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-decoration: none;
      background-color: transparent !important;

      &.active {
        background-color: transparent;
        border-bottom: 3px solid $secondary;
        color: $secondary !important;
      }
    }

    > .dropdown-menu {
      margin-top: 0.4rem !important;
    }     

    .dropdown-menu {
      box-shadow: 0 10px 10px rgba(150, 152, 167, 0.15);
      border-radius: 10px;
      border: 0;      

      .dropdown-item {
        padding-bottom: 5px;
        cursor: pointer;
        background-color: transparent;
        font-size: 0.875rem;
        color: $primary;

        &:hover, &:active {
          background-color: transparent;
        }
      }
    }
  }

  &.nav__subheader-admin {
    .nav-link.active {
      font-family: dmsansbold, Arial, sans-serif;
    }
  }

  & .nav__subheader-projects {
    .dropdown-menu {
      background-color: $secondary;
      border-radius: 0;

      .dropdown-toggle.nav-link {
        color: white !important;
        padding: 0.5rem 1rem;

        &::after {
          position: absolute;
          right: 1rem;
          top: 0.9rem;
          border: solid $gray15;
          border-width: 0 2px 2px 0;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }

      .dropdown-item {
        color: white !important;
        font-size: 1rem;
      }
    }
  }
}


.nav__item-mobile {

  @include media-breakpoint-down(sm) {
    .overlay {
      content: " ";
      background-color: black;
      opacity: 0.4;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 999;
    }

    .nav__item-mobile__content {
      padding: 1rem;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1001;
      background-color: $body-color;
      color: white;
      overflow: auto;

      &.collapsing {
        height: 100vh !important;
      }

      .avatar {
        width: 60px;
        height: 60px;
      }

      & > .nav-item {
        border-top: 0.5px solid $gray13;
        padding: 0.8rem 0;
      }

      .accordion-item {
        background-color: $body-color;
        color: white;

        .accordion-button {
          color: white;
          background-color: $body-color !important;
          font-family: dmsans, Arial, sans-serif;

          &:after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          }
        }

        .accordion-body {
          .nav-link {
            color: $gray2;
            font-size: $font-size-sm;
          }

          .nav-item:not(:first-of-type) .nav-link {
            padding-top: 0.8rem;
            padding-bottom: 0;
          }
        }
      }

      .accordion-account .accordion-item {
        background-color: $primary;
        border-radius: 7px;
        margin-top: 1rem;
        margin-bottom: 0.8rem;

        &:first-of-type .accordion-button {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }

        &:last-of-type .accordion-button.collapsed {
          border-bottom-right-radius: 7px;
          border-bottom-left-radius: 7px;
        }

        .accordion-body {
          padding: 0 0 0.75rem 0;
        }

        .accordion-header button {
          background-color: $primary !important;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }

        .accordion-body .nav-link,
        .accordion-header button {
          font-size: 0.875rem;
          font-family: dmsansmedium, sans-serif;
          color: white;
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      .navbar-toggler {
        text-align: right;
        font-size: 1rem;
        padding: 0;
      }
    }
  }
}
