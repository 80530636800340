
.carousel {

  &-slider {

    &--right-arrow,
    &--left-arrow {
      top: 50%;
      position: absolute;
      z-index: 1000;
      color: white !important;
      font-size: 25px;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &--right-arrow {
      right: 0;
    }

    &--play {
      position: absolute;
      z-index: 1000;
      top: 48%;
      left: 45%;
      color: white !important;
      font-size: 25px;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      width: 70px;
      height: 70px;
      padding: 0;

      i {
        z-index: 1000;
      }
    }
  }
  &-dash {
    .carousel-indicators{
      margin-bottom: 0;
      button{
        background-color: $gray6 !important;
      }
      .active{
        background-color: $green1 !important;
      }
    }
  }

  .slide {

    img {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    iframe {
      width: 100%;
      margin: 0;
    }
  }
}
