@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~video-react/styles/scss/video-react";
@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "~jsvectormap/dist/css/jsvectormap.min.css";

@import "./overrides/buttons";
@import "./overrides/text";
@import "./overrides/modal";
@import "./overrides/form";
@import "./overrides/card";
@import "./overrides/accordion";
@import "./overrides/badge";
@import "./overrides/pagination";
@import "./overrides/progress";

@import "./common/body";
@import "./common/fonts";
@import "./common/text";
@import "./common/buttons";
@import "./common/nav";
@import "./common/card";
@import "./common/icons";
@import "./common/table";
@import "./common/image";
@import "./common/utils";
@import "./common/form";
@import "./common/carousel";
@import "./common/dropzone";

.chart-legend {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin-right: 0.5rem;

  &--orange {
    background-color: $orange3;
  }

  &--green {
    background-color: $green1;
  }
}
