.input-filters {
  margin: 0;

  .form-control {
    border-radius: 7px;
    border: 0;
    font-size: $font-size-sm;
  }

  .input-group .input-group-text {
    border: 0;
  }
}

.table__page-size {
  margin: 0;

  .custom-select {
    &__container {
      border: none !important;
      border-radius: 10px;
      padding: 0 $input-padding-x-sm !important;
      min-width: 120px !important;

      @include media-breakpoint-down(sm){
        min-width: 60px !important;
      }
    }

    &__single-value {
      color: $primary !important;
      font-size: $font-size-base * .75 !important;
      font-family: dmsansmedium, Arial, sans-serif;
    }

    &__option {
      font-size: $font-size-base * .75 !important;
      color: $primary !important;
      font-family: dmsans, Arial, sans-serif;
      &:hover {
        background-color: transparent !important;
      }
      &--is-selected {
        font-family: dmsansbold, Arial, sans-serif;
        background-color: transparent !important;
      }
    }


    &__menu {
      margin-left: -5px !important;
    }
  }
}


.custom-select {
  &__container {
    padding-left: $input-padding-x !important;
    padding-right: $input-padding-x !important;
    border-radius: $input-border-radius;
  }

  &__value-container {
    padding: 0 !important;
    line-height: 1 !important;
  }

  &__single-value {
    color: $body-color !important;
    font-size: $font-size-base !important;
    margin: 0 !important;
  }

  &__control {
    border: 0 !important;
    box-shadow: none !important;
    min-height: unset !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 0 !important;
    color: $primary !important;
  }

  &__menu {
    z-index: 2000;
    border-radius: 0 !important;
    margin: 1px 0 !important;
    border: 1px solid $gray1 !important;
    border-top: 0 !important;
    box-shadow: none !important;
    margin-left: -15px !important;
  }

  &__option {
    background-color: white !important;
    color: $body-color !important;
    font-size: $font-size-sm !important;

    &--is-selected {
      background-color: $gray1 !important;
      color: $body-color !important;
    }

    &:active, &:hover {
      background-color: $gray1 !important;
    }
  }
}
